<template id="clipboard">
  <section class="loader">
    <div class="page-header">
      <h3 class="page-title"> Informations de la page "site 1" </h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="javascript:void(0);">Nom de la page</a></li>
          <li class="breadcrumb-item active" aria-current="page">Information de la page</li>
        </ol>
      </nav>
    </div>
    <div class="row">
      <div class="col-12 grid-margin">
        <div class="row">
          <div class="col-md-1 offset-md-11">
            <button class="btn btn-social-icon btn-outline-success mb-4" @click="sectionContent.editorHidden = !sectionContent.editorHidden">
              <i class="fa fa-plus"></i>
            </button>
          </div>
        </div>
        <div>
          <div class="row">
            <div class="col-md-12 grid-margin stretch-card" v-for="(sectionContent,i) in section" :key="i">
              <div class="card">
                <div class="card-body">
                  <router-link to="/sitesApp/site_page/information">
                    <h4 class="card-title">{{sectionContent.title}}</h4>
                  </router-link>
                <div class="row">
                  <div class="col-10">
                    <p class="card-description">{{sectionContent.descriptionContent}}</p>
                  </div>
                  <div class="col-2">
                    <button class="btn btn-social-icon btn-outline-facebook" @click="sectionContent.editorHidden = !sectionContent.editorHidden">
                      <i class="fa fa-edit"></i>
                    </button>
                    <button class="btn btn-social-icon btn-outline-youtube" @click="sectionContent.editorHidden = !sectionContent.editorHidden">
                      <i class="fa fa-trash"></i>
                    </button>
                  </div>
                </div>
                <!-- vue-trix -->
                <div class="row mt-4" v-if="sectionContent.editorHidden">
                  <div class="col-12">
                    <VueTrix v-model="sectionContent.descriptionContent" placeholder="Entrer la modification" localStorage/>
                    <div class="mt-3">
                      <button type="button" class="btn btn-info btn-clipboard" @click="doCopyTxtBox">Valider</button>
                    </div>
                  </div>
                </div>
                <!-- <input type="text" v-model="txtBoxModel" class="form-control"> -->
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Vue from 'vue'
import VueClipboard from 'vue-clipboard2'
import VueTrix from "vue-trix";
const axios = require ('axios').default;
 
VueClipboard.config.autoSetContainer = true // add this line
Vue.use(VueClipboard)

export default {
    name: 'sitePage',
    components: {
      VueTrix
    },
    data: function () {
      return {
        subMenu:'sitePage',
        txtBoxModel: 'Copied text box content',
        txtAreaModel: 'Copied text area content',
        txtParagraph:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',
        // sectionContent: [
        //   {sectionTitle:'Section 1', descriptionContent: 'Contenu de la section 1',editorHidden: false}
        // ],
        section: [
          {editorHidden: false}
        ]
      }
    },
    mounted () {
      axios.get('https://sites.comolapapaya.com/sections')
      .then (res => {
        this.section=res.data.result
      })
      .catch ( err => {
        console.log ('err', err)
      })
    },
    methods: {
      doCopyTxtBox: function () {
        this.$copyText(this.txtBoxModel).then(function () {})
      },
      doCopyTxtArea: function () {
          this.$copyText(this.txtAreaModel).then(function () {})
      },
        doCopyParagraph: function () {
          this.$copyText(this.txtParagraph).then(function () {})
      }
    }
  }
</script>

